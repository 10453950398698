exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "/* #E9F1FC */\n/* #D9E3F2 */\n/* 90% transparent version of motion-primary */\n/* #FFFFFF */\n/* 25% transparent version of ui-white */\n/* 25% transparent version of ui-white */\n/* 25% transparent version of ui-white */\n/* 15% transparent version of black */\n/* #575E75 */\n/* 35% transparent version of motion-primary */\n/* 15% transparent version of motion-primary */\n/* opt-in theme overrides */\n/* #CF63CF */\n/* #BD42BD */\n/* #FFAB19 */\n/* #FF8C1A */\n/* #0FBD8C */\n/* #0FBD8C */\n/* #FF8C1A */\n/* #FFB366 */\n/* #FF8C1A */\n/* 35% transparent version of extensions-primary */\n/* opaque version of extensions-transparent, on white bg */\n/* lighter than motion-primary */\n/* make sure to keep these in sync with other constants,\ne.g. STAGE_DIMENSION_DEFAULTS in lib/screen-utils.js */\n/* layout contants from `layout-constants.js` */\n/* Position the language select over the language icon, and make it transparent */\n.language-selector_language-select_8Vfnm {\n    position: absolute;\n    width: 3rem;\n    height: 3rem;\n    opacity: 0;\n    -webkit-user-select: none;\n       -moz-user-select: none;\n        -ms-user-select: none;\n            user-select: none;\n    font-family: \"Helvetica Neue\", Helvetica, Arial, sans-serif;\n    font-size: .875rem;\n    color: hsla(225, 15%, 40%, 1);\n    background: hsla(0, 100%, 100%, 1);\n}\n[theme=\"dark\"] .language-selector_language-select_8Vfnm {\n    color: hsla(0, 100%, 100%, 1);\n    background: var(--ui-primary, #e5e7eb);\n}\n[dir=\"ltr\"] .language-selector_language-select_8Vfnm {\n    right: 0;\n}\n[dir=\"rtl\"] .language-selector_language-select_8Vfnm {\n    left: 0;\n}\n.language-selector_language-select_8Vfnm option {\n    opacity: 1;\n}\n.language-selector_language-select_8Vfnm:focus {\n    border: 1px solid white;\n}\n", ""]);

// exports
exports.locals = {
	"language-select": "language-selector_language-select_8Vfnm",
	"languageSelect": "language-selector_language-select_8Vfnm"
};