exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "/* #E9F1FC */\n/* #D9E3F2 */\n/* 90% transparent version of motion-primary */\n/* #FFFFFF */\n/* 25% transparent version of ui-white */\n/* 25% transparent version of ui-white */\n/* 25% transparent version of ui-white */\n/* 15% transparent version of black */\n/* #575E75 */\n/* 35% transparent version of motion-primary */\n/* 15% transparent version of motion-primary */\n/* opt-in theme overrides */\n/* #CF63CF */\n/* #BD42BD */\n/* #FFAB19 */\n/* #FF8C1A */\n/* #0FBD8C */\n/* #0FBD8C */\n/* #FF8C1A */\n/* #FFB366 */\n/* #FF8C1A */\n/* 35% transparent version of extensions-primary */\n/* opaque version of extensions-transparent, on white bg */\n/* lighter than motion-primary */\n/* make sure to keep these in sync with other constants,\ne.g. STAGE_DIMENSION_DEFAULTS in lib/screen-utils.js */\n/* layout contants from `layout-constants.js` */\n.play-button_play-button_3MkN- {\n    display: flex;\n    align-items: center;\n    justify-content: center;\n\n    overflow: hidden;  /* Mask the icon animation */\n    width: 2.5rem;\n    height: 2.5rem;\n    background-color: hsla(300, 53%, 60%, 1);\n    color: hsla(0, 100%, 100%, 1);\n    border-radius: 50%;\n    font-family: \"Helvetica Neue\", Helvetica, Arial, sans-serif;\n    -webkit-user-select: none;\n       -moz-user-select: none;\n        -ms-user-select: none;\n            user-select: none;\n    cursor: pointer;\n    transition: all 0.15s ease-out;\n}\n.play-button_play-button_3MkN- {\n    position: absolute;\n    top: .5rem;\n    z-index: auto;\n}\n.play-button_play-button_3MkN-:focus {\n    outline: none;\n}\n.play-button_play-icon_CX5nH {\n  width: 50%;\n}\n[dir=\"ltr\"] .play-button_play-button_3MkN- {\n    right: .5rem;\n}\n[dir=\"rtl\"] .play-button_play-button_3MkN- {\n    left: .5rem;\n}\n", ""]);

// exports
exports.locals = {
	"play-button": "play-button_play-button_3MkN-",
	"playButton": "play-button_play-button_3MkN-",
	"play-icon": "play-button_play-icon_CX5nH",
	"playIcon": "play-button_play-icon_CX5nH"
};