exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "/* make sure to keep these in sync with other constants,\ne.g. STAGE_DIMENSION_DEFAULTS in lib/screen-utils.js */\n\n/* layout contants from `layout-constants.js` */\n\n/* #E9F1FC */\n\n/* #D9E3F2 */\n\n/* 90% transparent version of motion-primary */\n\n/* #FFFFFF */\n\n/* 25% transparent version of ui-white */\n\n/* 25% transparent version of ui-white */\n\n/* 25% transparent version of ui-white */\n\n/* 15% transparent version of black */\n\n/* #575E75 */\n\n/* 35% transparent version of motion-primary */\n\n/* 15% transparent version of motion-primary */\n\n/* opt-in theme overrides */\n\n/* #CF63CF */\n\n/* #BD42BD */\n\n/* #FFAB19 */\n\n/* #FF8C1A */\n\n/* #0FBD8C */\n\n/* #0FBD8C */\n\n/* #FF8C1A */\n\n/* #FFB366 */\n\n/* #FF8C1A */\n\n/* 35% transparent version of extensions-primary */\n\n/* opaque version of extensions-transparent, on white bg */\n\n/* lighter than motion-primary */\n\n.label_input-group_2vTky {\n    display: inline-flex;\n    flex-direction: row;\n    align-items: center;\n}\n\n.label_input-group-column_22FuU {\n    display: inline-flex;\n    flex-direction: column;\n    align-items: flex-start;\n}\n\n.label_input-group-column_22FuU span {\n    margin-bottom: .25rem;\n}\n\n.label_input-label_3KjCa, .label_input-label-secondary_3QDNV {\n    font-size: 0.625rem;\n    -webkit-user-select: none;\n       -moz-user-select: none;\n        -ms-user-select: none;\n            user-select: none;\n    cursor: default;\n\n    white-space: nowrap;\n}\n\n[dir=\"ltr\"] .label_input-label_3KjCa, [dir=\"ltr\"] .label_input-label-secondary_3QDNV {\n    margin-right: .5rem;\n}\n\n[dir=\"rtl\"] .label_input-label_3KjCa, [dir=\"rtl\"] .label_input-label-secondary_3QDNV {\n    margin-left: .5rem;\n}\n\n.label_input-label_3KjCa {\n    font-weight: bold;\n}\n", ""]);

// exports
exports.locals = {
	"input-group": "label_input-group_2vTky",
	"inputGroup": "label_input-group_2vTky",
	"input-group-column": "label_input-group-column_22FuU",
	"inputGroupColumn": "label_input-group-column_22FuU",
	"input-label": "label_input-label_3KjCa",
	"inputLabel": "label_input-label_3KjCa",
	"input-label-secondary": "label_input-label-secondary_3QDNV",
	"inputLabelSecondary": "label_input-label-secondary_3QDNV"
};