exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "/* #E9F1FC */\n/* #D9E3F2 */\n/* 90% transparent version of motion-primary */\n/* #FFFFFF */\n/* 25% transparent version of ui-white */\n/* 25% transparent version of ui-white */\n/* 25% transparent version of ui-white */\n/* 15% transparent version of black */\n/* #575E75 */\n/* 35% transparent version of motion-primary */\n/* 15% transparent version of motion-primary */\n/* opt-in theme overrides */\n/* #CF63CF */\n/* #BD42BD */\n/* #FFAB19 */\n/* #FF8C1A */\n/* #0FBD8C */\n/* #0FBD8C */\n/* #FF8C1A */\n/* #FFB366 */\n/* #FF8C1A */\n/* 35% transparent version of extensions-primary */\n/* opaque version of extensions-transparent, on white bg */\n/* lighter than motion-primary */\n/* make sure to keep these in sync with other constants,\ne.g. STAGE_DIMENSION_DEFAULTS in lib/screen-utils.js */\n/* layout contants from `layout-constants.js` */\n.author-info_author-info_2Pliw {\n    color: hsla(0, 100%, 100%, 1);\n    font-family: \"Helvetica Neue\", Helvetica, Arial, sans-serif;\n    display: flex;\n    justify-content: flex-start;\n    align-items: center;\n    cursor: default;\n}\n.author-info_avatar_31Mo8 {\n    margin-right: .5625rem;\n}\n.author-info_project-title_h7Gcj {\n    max-width: 12rem;\n    display: block;\n    overflow: hidden;\n    font-size: 0.875rem;\n    font-weight: bold;\n    text-overflow: ellipsis;\n    white-space: nowrap;\n    margin: 0;\n    padding: 0;\n}\n.author-info_username-line_18E8B {\n    max-width: 12rem;\n    font-size: 0.75rem;\n    display: block;\n    overflow: hidden;\n    font-weight: normal;\n    text-overflow: ellipsis;\n    white-space: nowrap;\n}\n.author-info_username_2Ov2- {\n    font-weight: bold;\n}\n.author-info_link_3EUX3 {\n    text-decoration: none;\n    color: hsla(0, 100%, 100%, 1) !important;\n}\n.author-info_link_3EUX3:hover {\n    text-decoration: underline;\n}\n.author-info_link_3EUX3 .author-info_author-info_2Pliw {\n    cursor: pointer;\n}\n", ""]);

// exports
exports.locals = {
	"author-info": "author-info_author-info_2Pliw",
	"authorInfo": "author-info_author-info_2Pliw",
	"avatar": "author-info_avatar_31Mo8",
	"project-title": "author-info_project-title_h7Gcj",
	"projectTitle": "author-info_project-title_h7Gcj",
	"username-line": "author-info_username-line_18E8B",
	"usernameLine": "author-info_username-line_18E8B",
	"username": "author-info_username_2Ov2-",
	"link": "author-info_link_3EUX3"
};