exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "/* #E9F1FC */\n/* #D9E3F2 */\n/* 90% transparent version of motion-primary */\n/* #FFFFFF */\n/* 25% transparent version of ui-white */\n/* 25% transparent version of ui-white */\n/* 25% transparent version of ui-white */\n/* 15% transparent version of black */\n/* #575E75 */\n/* 35% transparent version of motion-primary */\n/* 15% transparent version of motion-primary */\n/* opt-in theme overrides */\n/* #CF63CF */\n/* #BD42BD */\n/* #FFAB19 */\n/* #FF8C1A */\n/* #0FBD8C */\n/* #0FBD8C */\n/* #FF8C1A */\n/* #FFB366 */\n/* #FF8C1A */\n/* 35% transparent version of extensions-primary */\n/* opaque version of extensions-transparent, on white bg */\n/* lighter than motion-primary */\n.security-manager-modal_modal-content_3qWwH {\n    width: 400px;\n}\n.security-manager-modal_body_Pn7qy {\n    background: hsla(0, 100%, 100%, 1);\n    padding: 1.5rem 2.25rem;\n}\n[theme=\"dark\"] .security-manager-modal_body_Pn7qy {\n    color: var(--text-primary, hsla(225, 15%, 40%, 1));\n    background: var(--ui-primary, #e5e7eb);\n}\n.security-manager-modal_body_Pn7qy p {\n    margin: 8px 0;\n}\n.security-manager-modal_extension_1ASvZ {\n    font-family: monospace;\n    -webkit-user-select: text;\n       -moz-user-select: text;\n        -ms-user-select: text;\n            user-select: text;\n}\n.security-manager-modal_buttons_1LSKA {\n    display: flex;\n    justify-content: flex-end;\n}\n.security-manager-modal_deny-button_3Vd-R, .security-manager-modal_allow-button_3tcXk {\n    font: inherit;\n    color: inherit;\n    padding: 0.75rem 1rem;\n    border-radius: 0.25rem;\n    border: 1px solid var(--ui-black-transparent, hsla(0, 0%, 0%, 0.15));\n    font-weight: 600;\n    font-size: 0.85rem;\n    margin: 0 0 0 8px;\n}\n.security-manager-modal_deny-button_3Vd-R {\n    background-color: rgb(255, 92, 92);\n}\n.security-manager-modal_allow-button_3tcXk {\n    background-color: #24cd11;\n}\n", ""]);

// exports
exports.locals = {
	"modal-content": "security-manager-modal_modal-content_3qWwH",
	"modalContent": "security-manager-modal_modal-content_3qWwH",
	"body": "security-manager-modal_body_Pn7qy",
	"extension": "security-manager-modal_extension_1ASvZ",
	"buttons": "security-manager-modal_buttons_1LSKA",
	"deny-button": "security-manager-modal_deny-button_3Vd-R",
	"denyButton": "security-manager-modal_deny-button_3Vd-R",
	"allow-button": "security-manager-modal_allow-button_3tcXk",
	"allowButton": "security-manager-modal_allow-button_3tcXk"
};