exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "/* #E9F1FC */\n/* #D9E3F2 */\n/* 90% transparent version of motion-primary */\n/* #FFFFFF */\n/* 25% transparent version of ui-white */\n/* 25% transparent version of ui-white */\n/* 25% transparent version of ui-white */\n/* 15% transparent version of black */\n/* #575E75 */\n/* 35% transparent version of motion-primary */\n/* 15% transparent version of motion-primary */\n/* opt-in theme overrides */\n/* #CF63CF */\n/* #BD42BD */\n/* #FFAB19 */\n/* #FF8C1A */\n/* #0FBD8C */\n/* #0FBD8C */\n/* #FF8C1A */\n/* #FFB366 */\n/* #FF8C1A */\n/* 35% transparent version of extensions-primary */\n/* opaque version of extensions-transparent, on white bg */\n/* lighter than motion-primary */\n/* make sure to keep these in sync with other constants,\ne.g. STAGE_DIMENSION_DEFAULTS in lib/screen-utils.js */\n/* layout contants from `layout-constants.js` */\n.user-avatar_user-thumbnail_jSYS1 {\n    width: 2rem;\n    height: 2rem;\n    border-radius: calc(0.5rem / 2);\n    vertical-align: middle;\n    box-shadow: 0 0 0 1px var(--ui-black-transparent, hsla(0, 0%, 0%, 0.15));\n}\n", ""]);

// exports
exports.locals = {
	"user-thumbnail": "user-avatar_user-thumbnail_jSYS1",
	"userThumbnail": "user-avatar_user-thumbnail_jSYS1"
};