exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "/* #E9F1FC */\n/* #D9E3F2 */\n/* 90% transparent version of motion-primary */\n/* #FFFFFF */\n/* 25% transparent version of ui-white */\n/* 25% transparent version of ui-white */\n/* 25% transparent version of ui-white */\n/* 15% transparent version of black */\n/* #575E75 */\n/* 35% transparent version of motion-primary */\n/* 15% transparent version of motion-primary */\n/* opt-in theme overrides */\n/* #CF63CF */\n/* #BD42BD */\n/* #FFAB19 */\n/* #FF8C1A */\n/* #0FBD8C */\n/* #0FBD8C */\n/* #FF8C1A */\n/* #FFB366 */\n/* #FF8C1A */\n/* 35% transparent version of extensions-primary */\n/* opaque version of extensions-transparent, on white bg */\n/* lighter than motion-primary */\n.spinner_container_2gp3A {\n    display: flex;\n    width: 100%;\n    height: 100%;\n    align-items: center;\n    justify-content: center;\n}\n.spinner_spinner_3_DJ6 {\n    width: 64px;\n    height: 64px;\n    border-radius: 50%;\n    border: 6px solid;\n    border-color: #10b981 transparent #10b981 transparent;\n    animation: spinner_spinner_3_DJ6 1.2s linear infinite;\n}\n[theme=\"dark\"] .spinner_spinner_3_DJ6 {\n    border-color: #fff transparent #fff transparent;\n}\n@keyframes spinner_spinner_3_DJ6 {\n    0% {\n        transform: rotate(0deg);\n    }\n    100% {\n        transform: rotate(360deg);\n    }\n}\n", ""]);

// exports
exports.locals = {
	"container": "spinner_container_2gp3A",
	"spinner": "spinner_spinner_3_DJ6"
};