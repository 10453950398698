exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "/* #E9F1FC */\n/* #D9E3F2 */\n/* 90% transparent version of motion-primary */\n/* #FFFFFF */\n/* 25% transparent version of ui-white */\n/* 25% transparent version of ui-white */\n/* 25% transparent version of ui-white */\n/* 15% transparent version of black */\n/* #575E75 */\n/* 35% transparent version of motion-primary */\n/* 15% transparent version of motion-primary */\n/* opt-in theme overrides */\n/* #CF63CF */\n/* #BD42BD */\n/* #FFAB19 */\n/* #FF8C1A */\n/* #0FBD8C */\n/* #0FBD8C */\n/* #FF8C1A */\n/* #FFB366 */\n/* #FF8C1A */\n/* 35% transparent version of extensions-primary */\n/* opaque version of extensions-transparent, on white bg */\n/* lighter than motion-primary */\n.loupe_color-picker_1OaU- {\n    position: absolute;\n    top: 0;\n    left: 0;\n    border-radius: 100%;\n    border: 4px solid hsla(0, 0%, 0%, 0.15);\n}\n", ""]);

// exports
exports.locals = {
	"color-picker": "loupe_color-picker_1OaU-",
	"colorPicker": "loupe_color-picker_1OaU-"
};