exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "/* #E9F1FC */\n/* #D9E3F2 */\n/* 90% transparent version of motion-primary */\n/* #FFFFFF */\n/* 25% transparent version of ui-white */\n/* 25% transparent version of ui-white */\n/* 25% transparent version of ui-white */\n/* 15% transparent version of black */\n/* #575E75 */\n/* 35% transparent version of motion-primary */\n/* 15% transparent version of motion-primary */\n/* opt-in theme overrides */\n/* #CF63CF */\n/* #BD42BD */\n/* #FFAB19 */\n/* #FF8C1A */\n/* #0FBD8C */\n/* #0FBD8C */\n/* #FF8C1A */\n/* #FFB366 */\n/* #FF8C1A */\n/* 35% transparent version of extensions-primary */\n/* opaque version of extensions-transparent, on white bg */\n/* lighter than motion-primary */\n/* make sure to keep these in sync with other constants,\ne.g. STAGE_DIMENSION_DEFAULTS in lib/screen-utils.js */\n/* layout contants from `layout-constants.js` */\n.inline-message_inline-message_2feS3 {\n    color: hsla(0, 100%, 100%, 1);\n    font-family: \"Helvetica Neue\", Helvetica, Arial, sans-serif;\n    display: flex;\n    justify-content: flex-end;\n    align-items: center;\n    font-size: .8125rem;\n}\n.inline-message_success_1jfE0 {\n    color: hsla(0, 100%, 100%, 0.75);\n}\n.inline-message_info_E7dNO {\n    color: hsla(0, 100%, 100%, 1);\n}\n.inline-message_warn_3DS5G {\n    color: hsla(30, 100%, 70%, 1);\n}\n.inline-message_spinner_1vybW {\n    margin-right: 0.5rem;\n}\n", ""]);

// exports
exports.locals = {
	"inline-message": "inline-message_inline-message_2feS3",
	"inlineMessage": "inline-message_inline-message_2feS3",
	"success": "inline-message_success_1jfE0",
	"info": "inline-message_info_E7dNO",
	"warn": "inline-message_warn_3DS5G",
	"spinner": "inline-message_spinner_1vybW"
};