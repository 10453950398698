exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "/* #E9F1FC */\n/* #D9E3F2 */\n/* 90% transparent version of motion-primary */\n/* #FFFFFF */\n/* 25% transparent version of ui-white */\n/* 25% transparent version of ui-white */\n/* 25% transparent version of ui-white */\n/* 15% transparent version of black */\n/* #575E75 */\n/* 35% transparent version of motion-primary */\n/* 15% transparent version of motion-primary */\n/* opt-in theme overrides */\n/* #CF63CF */\n/* #BD42BD */\n/* #FFAB19 */\n/* #FF8C1A */\n/* #0FBD8C */\n/* #0FBD8C */\n/* #FF8C1A */\n/* #FFB366 */\n/* #FF8C1A */\n/* 35% transparent version of extensions-primary */\n/* opaque version of extensions-transparent, on white bg */\n/* lighter than motion-primary */\n/* Copied from scratch-paint color-picker.css. */\n.Popover-body {\n    background: white;\n    border: 1px solid #ddd;\n    padding: 4px;\n    border-radius: 4px;\n    padding: 4px;\n    box-shadow: 0px 0px 8px 1px rgba(0, 0, 0, .3);\n}\n.Popover-tipShape {\n    fill: white;\n    stroke: #ddd;\n}\n.direction-picker_button-row_1DF8U {\n    display: flex;\n    flex-direction: row;\n    justify-content: center;\n\n}\n.direction-picker_icon-button_1y9Rd {\n    margin: 0.25rem;\n    border: none;\n    background: none;\n    outline: none;\n    cursor: pointer;\n    -webkit-user-select: none;\n       -moz-user-select: none;\n        -ms-user-select: none;\n            user-select: none;\n}\n.direction-picker_icon-button_1y9Rd:active > img {\n    width: 20px;\n    height: 20px;\n    transform: scale(1.15);\n}\n.direction-picker_icon-button_1y9Rd > img {\n    transition: transform 0.1s;\n    filter: grayscale(100%);\n}\n.direction-picker_icon-button_1y9Rd.direction-picker_active_10_kJ > img {\n    filter: none;\n}\n", ""]);

// exports
exports.locals = {
	"button-row": "direction-picker_button-row_1DF8U",
	"buttonRow": "direction-picker_button-row_1DF8U",
	"icon-button": "direction-picker_icon-button_1y9Rd",
	"iconButton": "direction-picker_icon-button_1y9Rd",
	"active": "direction-picker_active_10_kJ"
};