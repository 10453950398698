exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "/* #E9F1FC */\n/* #D9E3F2 */\n/* 90% transparent version of motion-primary */\n/* #FFFFFF */\n/* 25% transparent version of ui-white */\n/* 25% transparent version of ui-white */\n/* 25% transparent version of ui-white */\n/* 15% transparent version of black */\n/* #575E75 */\n/* 35% transparent version of motion-primary */\n/* 15% transparent version of motion-primary */\n/* opt-in theme overrides */\n/* #CF63CF */\n/* #BD42BD */\n/* #FFAB19 */\n/* #FF8C1A */\n/* #0FBD8C */\n/* #0FBD8C */\n/* #FF8C1A */\n/* #FFB366 */\n/* #FF8C1A */\n/* 35% transparent version of extensions-primary */\n/* opaque version of extensions-transparent, on white bg */\n/* lighter than motion-primary */\n.waveform_container_2K9Om {\n    width: 100%;\n}\n.waveform_waveform-path_TskyB {\n    /*\n        This color is lighter than sound-primary, but\n        cannot use alpha because of overlapping elements.\n    */\n    fill: hsl(300, 54%, 72%);\n    stroke: hsla(300, 48%, 50%, 1);\n}\n.waveform_baseline_2J5dw {\n    stroke: hsla(300, 48%, 50%, 1);\n}\n", ""]);

// exports
exports.locals = {
	"container": "waveform_container_2K9Om",
	"waveform-path": "waveform_waveform-path_TskyB",
	"waveformPath": "waveform_waveform-path_TskyB",
	"baseline": "waveform_baseline_2J5dw"
};