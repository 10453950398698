var escape = require("../../../node_modules/css-loader/lib/url/escape.js");
exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "/* #E9F1FC */\n/* #D9E3F2 */\n/* 90% transparent version of motion-primary */\n/* #FFFFFF */\n/* 25% transparent version of ui-white */\n/* 25% transparent version of ui-white */\n/* 25% transparent version of ui-white */\n/* 15% transparent version of black */\n/* #575E75 */\n/* 35% transparent version of motion-primary */\n/* 15% transparent version of motion-primary */\n/* opt-in theme overrides */\n/* #CF63CF */\n/* #BD42BD */\n/* #FFAB19 */\n/* #FF8C1A */\n/* #0FBD8C */\n/* #0FBD8C */\n/* #FF8C1A */\n/* #FFB366 */\n/* #FF8C1A */\n/* 35% transparent version of extensions-primary */\n/* opaque version of extensions-transparent, on white bg */\n/* lighter than motion-primary */\n.checkbox_checkbox_2QbUl {\n    -webkit-appearance: none;\n       -moz-appearance: none;\n            appearance: none;\n    width: 18px;\n    height: 18px;\n    border-radius: 4px;\n    transition: .2s;\n    background-color: white;\n    transition-property: background-color;\n    background-size: 100%;\n    border: 1px solid rgb(92, 92, 92);\n    outline: none;\n}\n.checkbox_checkbox_2QbUl:hover, .checkbox_checkbox_2QbUl:focus, .checkbox_checkbox_2QbUl:active, .checkbox_checkbox_2QbUl:checked {\n    border-color: #10b981;\n}\n.checkbox_checkbox_2QbUl:focus, .checkbox_checkbox_2QbUl:active {\n    box-shadow: 0 0 0 3px hsla(132, 100%, 65%, 0.35);\n}\n.checkbox_checkbox_2QbUl:checked {\n    background-color: #10b981;\n    background-image: url(" + escape(require("./checkbox.svg")) + ");\n}\n[theme=\"dark\"] .checkbox_checkbox_2QbUl {\n    border-color: transparent;\n}\n", ""]);

// exports
exports.locals = {
	"checkbox": "checkbox_checkbox_2QbUl"
};