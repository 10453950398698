exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "/* #E9F1FC */\n/* #D9E3F2 */\n/* 90% transparent version of motion-primary */\n/* #FFFFFF */\n/* 25% transparent version of ui-white */\n/* 25% transparent version of ui-white */\n/* 25% transparent version of ui-white */\n/* 15% transparent version of black */\n/* #575E75 */\n/* 35% transparent version of motion-primary */\n/* 15% transparent version of motion-primary */\n/* opt-in theme overrides */\n/* #CF63CF */\n/* #BD42BD */\n/* #FFAB19 */\n/* #FF8C1A */\n/* #0FBD8C */\n/* #0FBD8C */\n/* #FF8C1A */\n/* #FFB366 */\n/* #FF8C1A */\n/* 35% transparent version of extensions-primary */\n/* opaque version of extensions-transparent, on white bg */\n/* lighter than motion-primary */\n/* this class must contain \"see-inside-button\" somewhere for the s3 dev tools to be able to reinject itself */\n/* https://github.com/griffpatch/Scratch3-Dev-Tools/blob/0.2.4/inject3.js#L1804-L1807 */\n.tw-see-inside_see-inside-button_3ecsR {\n    box-shadow: 0 0 0 1px var(--ui-black-transparent, hsla(0, 0%, 0%, 0.15));\n}\n.tw-see-inside_see-inside-button-icon_1fQvM {\n    height: 1.25rem;\n}\n", ""]);

// exports
exports.locals = {
	"see-inside-button": "tw-see-inside_see-inside-button_3ecsR",
	"seeInsideButton": "tw-see-inside_see-inside-button_3ecsR",
	"see-inside-button-icon": "tw-see-inside_see-inside-button-icon_1fQvM",
	"seeInsideButtonIcon": "tw-see-inside_see-inside-button-icon_1fQvM"
};