exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "/* #E9F1FC */\n/* #D9E3F2 */\n/* 90% transparent version of motion-primary */\n/* #FFFFFF */\n/* 25% transparent version of ui-white */\n/* 25% transparent version of ui-white */\n/* 25% transparent version of ui-white */\n/* 15% transparent version of black */\n/* #575E75 */\n/* 35% transparent version of motion-primary */\n/* 15% transparent version of motion-primary */\n/* opt-in theme overrides */\n/* #CF63CF */\n/* #BD42BD */\n/* #FFAB19 */\n/* #FF8C1A */\n/* #0FBD8C */\n/* #0FBD8C */\n/* #FF8C1A */\n/* #FFB366 */\n/* #FF8C1A */\n/* 35% transparent version of extensions-primary */\n/* opaque version of extensions-transparent, on white bg */\n/* lighter than motion-primary */\n/* make sure to keep these in sync with other constants,\ne.g. STAGE_DIMENSION_DEFAULTS in lib/screen-utils.js */\n/* layout contants from `layout-constants.js` */\nbody {\n    font-family: \"Helvetica Neue\", Helvetica, Arial, sans-serif;\n}\nh2 {\n    font-size: 1.5rem;\n    font-weight: bold;\n}\np {\n    font-size: 1rem;\n    line-height: 1.5em;\n}\n.crash-message_crash-wrapper_25B61 {\n    background-color: #10b981;\n    width: 100%;\n    height: 100%;\n    display: flex;\n    justify-content: center;\n    align-items: center;\n}\n[theme=\"dark\"] .crash-message_crash-wrapper_25B61 {\n    background-color: #1e293b;\n}\n.crash-message_body_1q0lu {\n    width: 50%;\n    color: white;\n    text-align: center;\n}\n.crash-message_reloadButton_FoS7x {\n    border: 1px solid #10b981;\n    border-radius: 0.25rem;\n    padding: 0.5rem 2rem;\n    background: white;\n    color: #10b981;\n    font-weight: bold;\n    font-size: 0.875rem;\n    cursor: pointer;\n}\n.crash-message_header_1tEXc {\n    font-size: 1.5em;\n    font-weight: bold;\n}\n.crash-message_error-message_1pX4X {\n    font-family: monospace;\n}\n", ""]);

// exports
exports.locals = {
	"crash-wrapper": "crash-message_crash-wrapper_25B61",
	"crashWrapper": "crash-message_crash-wrapper_25B61",
	"body": "crash-message_body_1q0lu",
	"reloadButton": "crash-message_reloadButton_FoS7x",
	"header": "crash-message_header_1tEXc",
	"error-message": "crash-message_error-message_1pX4X",
	"errorMessage": "crash-message_error-message_1pX4X"
};